import React from "react"

import BackgroundVideo from "./BackgroundVideo"

import HeaderLogo from "../images/header-logo.png"

const HeroSection = () => (
  <div className="hero-container">
    <BackgroundVideo />
    <div className="hero-content">
      <div className="hero-title">
        <div className="hero-logo">
          <img src={HeaderLogo} alt="FitLab" />
        </div>
        <p>
          <b>FitLab</b> powers <b>experiences</b> that <b>inspire</b> you to{" "}
          <b>move.</b>
        </p>
      </div>
    </div>
  </div>
)

export default HeroSection
