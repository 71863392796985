import * as React from "react"
import brands from "../images/brands.png"

import seenOn1 from "../images/seen-on/seen-on-1.png"
import seenOn2 from "../images/seen-on/seen-on-2.png"
import seenOn3 from "../images/seen-on/seen-on-3.png"
import seenOn4 from "../images/seen-on/seen-on-4.png"
import seenOn5 from "../images/seen-on/seen-on-5.png"
import seenOn6 from "../images/seen-on/seen-on-6.png"
import seenOn7 from "../images/seen-on/seen-on-7.png"
import seenOn8 from "../images/seen-on/seen-on-8.png"
import seenOn9 from "../images/seen-on/seen-on-9.png"
import seenOn10 from "../images/seen-on/seen-on-10.png"

const mobileSeenOnSection = [
  [seenOn1, seenOn2],
  [seenOn3, seenOn4],
  [seenOn5, seenOn6],
  [seenOn7, seenOn8],
  [seenOn9, seenOn10],
]

const AsSeenOn = () => (
  <div className="as-seen-on">
    <div className="brands">
      <img src={brands} alt="brands" />
    </div>
    <div className="as-seen-on-mobile">
      {mobileSeenOnSection.map((section, sIndex) => (
        <div className="as-seen-on-section" key={sIndex}>
          {section.map((imgSrc, index) => (
            <img src={imgSrc} alt={`seen-on-${sIndex}-${index}`} />
          ))}
        </div>
      ))}
    </div>
  </div>
)

export default AsSeenOn
