import React from "react"

const BackgroundVideo = () => {
  // const videoSource = "https://d301snvfck3zr8.cloudfront.net/testmedia1/compilation-web_Generic_Hd_Mp4_Avc_Aac_16x9_Sdr_1920x1080p_30Hz_10000Kbps_Qvbr_Vq9.mp4"
  // const videoSourceMp4 = "https://d301snvfck3zr8.cloudfront.net/fitplan+web+compilation.mp4"
  // const videoSourceOgv = "https://d301snvfck3zr8.cloudfront.net/fitplan+web+compilation.ogv"
  // const videoSourceWebM = "https://d301snvfck3zr8.cloudfront.net/fitplan+web+compilation_1.webm"
  const videoSourceMp4 = "https://d301snvfck3zr8.cloudfront.net/fitlab-web220110.mp4"
  const videoSourceOgv = "https://d301snvfck3zr8.cloudfront.net/fitlab-web220110.ogv"
  const videoSourceWebM = "https://d301snvfck3zr8.cloudfront.net/fitlab-web220110.webm"
  return (
    <video autoPlay muted loop className="video" playsInline>
      <source src={videoSourceWebM} type="video/webm" />
      <source src={videoSourceOgv} type="video/ogv" />
      <source src={videoSourceMp4} type="video/mp4" />
      Your browser does not support video
    </video>
  )
}

export default BackgroundVideo
