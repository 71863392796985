import React from "react"
import PropTypes from "prop-types"

const FitLabBrands = ({ fitlabBrands }) => {
  return (
    <div className="brands-wrapper">
      {fitlabBrands.map(brand => (
        <div className="brand-container" key={brand.title}>
          <div className="brand-image">
            <img src={brand.imageSource} alt={brand.title} />
          </div>
          <div className="brand-content">
            <div>
              <p className="brand-title">{brand.title}</p>
              <p className="brand-description">{brand.description}</p>
            </div>
            <a
              href={brand.link || "#"}
              target="_blank"
              className="brand-link"
              rel="noreferrer"
            >
              Visit Website
            </a>
          </div>
        </div>
      ))}
    </div>
  )
}

FitLabBrands.propTypes = {
  fitlabBrands: PropTypes.array,
}

FitLabBrands.defaultProps = {
  fitlabBrands: [],
}

export default FitLabBrands
