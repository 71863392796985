import React from "react"
import PropTypes from "prop-types"

import "react-multi-carousel/lib/styles.css"
import CarouselComponent from "react-multi-carousel"
import arrow from "../images/arrow.png"

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    partialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    partialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 30,
  },
}

const Arrow = ({ onClick, left }) => (
  <div
    className={`carousel-arrow ${left ? "left" : "right"}`}
    onClick={onClick}
  >
    <img src={arrow} alt="arrow" />
  </div>
)

const Carousel = ({
  children,
  partialVisible,
  slidesToSlide,
  autoPlay,
  responsive,
  infinite,
}) => {
  return (
    <CarouselComponent
      partialVisible={partialVisible}
      autoPlay={autoPlay}
      responsive={responsive}
      customRightArrow={<Arrow />}
      customLeftArrow={<Arrow left />}
      slidesToSlide={slidesToSlide}
      infinite={infinite}
    >
      {children}
    </CarouselComponent>
  )
}

Carousel.propTypes = {
  children: PropTypes.node,
  partialVisible: PropTypes.bool,
  slidesToSlide: PropTypes.number,
  autoPlay: PropTypes.bool,
  responsive: PropTypes.object,
  infinite: PropTypes.bool,
}

Carousel.defaultProps = {
  partialVisible: false,
  slidesToSlide: 1,
  autoPlay: false,
  responsive: responsive,
  infinite: false,
}

export default Carousel
