import React from "react"
import parse from 'html-react-parser'
import PropTypes from "prop-types"

const MediaRelations = ({ mediaRelations }) => (
  <div className="media-relations-wrapper">
    {mediaRelations.map(rel => (
      <div className="relation-container" key={rel.title}>
        <p className="relation-date">{rel.date}</p>
        <p className="relation-title">{parse(rel.title)}</p>
        <p className="relation-link">
          <a href={rel.link} target="_blank" rel="noreferrer">
            {rel.label}
          </a>
        </p>
      </div>
    ))}
  </div>
)

MediaRelations.propTypes = {
  mediaRelations: PropTypes.array,
}

MediaRelations.defaultProps = {
  mediaRelations: [],
}

export default MediaRelations
