import React from "react"
import useLocalStorage from "../hooks/useLocalStorage"
import "../scss/cookies.scss"

const Cookies = ({ content: { acceptLabel, declineLabel, message } }) => {
  const [showCookieMessage, setShowCookieMessage] = useLocalStorage(
    "showCookieMessage",
    true
  )
  const [hasAcceptedCookies, setHasAcceptedCookies] = useLocalStorage(
    "hasAcceptedCookies",
    true
  )

  const manageCookies = hasAccepted => {
    setShowCookieMessage(false)
    setHasAcceptedCookies(hasAccepted)
  }

  // if cookies declined, set the GA global property to restrict tracking
  if (hasAcceptedCookies === false) {
    if (typeof window !== `undefined`) {
      window["ga-disable-UA-154394559-1"] = true
    }
  }

  return (
    <>
      {showCookieMessage && (
        <section className="cookies-wrapper">
          <div className="cookies-container">
            <div className="inner">
              <div className="text">
                <p className="message">{message}</p>
                <ul className="links">
                  <li>
                    <button
                      className="accept"
                      onClick={() => manageCookies(true)}
                    >
                      {acceptLabel}
                    </button>
                  </li>
                  <li>
                    <button
                      className="decline"
                      onClick={() => manageCookies(false)}
                    >
                      {declineLabel}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default Cookies
